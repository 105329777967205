@font-face {
  font-family: "GillroyRegular";
  src: url("./Fonts/GilroyRegular.ttf") format("truetype");
}

.regular {
  font-family: GillroyRegular;
}

@font-face {
  font-family: "GillroyMedium";
  src: url("./Fonts/GilroyMedium.ttf") format("truetype");
}

.medium {
  font-family: GillroyMedium;
}

@font-face {
  font-family: "GillroySemiBold";
  src: url("./Fonts/GilroySemiBold.ttf") format("truetype");
}

.semiBold {
  font-family: GillroySemiBold;
}
@font-face {
  font-family: "GillroyBold";
  src: url("./Fonts/GilroyBold.ttf") format("truetype");
}

.semiBold {
  font-family: GillroyBold;
}

a {
  text-decoration: none !important;
}

.form-control:focus {
  background-color: #1a1a1a !important;
  box-shadow: none !important;
  color: #ffffff !important;
  /* color: #ffffff !important; */
}

.common-icon-size {
  width: 16px !important;
  height: 16px !important;
}

.search-input-custom-class::-webkit-search-cancel-button {
  background: #f8f8f8;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {}
@media only screen and (min-width: 768px) and (max-width: 991px) {}
@media only screen and (min-width: 576px) and (max-width: 767px) {}
 */

@media (max-width: 575px) {
  .common-icon-size {
    width: 14px !important;
    height: 14px !important;
  }
}

body {
  background-color: #1a1a1a;
}
