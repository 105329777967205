.hl_cardName {
  color: #f8f8f8;
  font-size: 16px;
  font-family: "GillroyMedium";
  margin-top: 16px;
}

.hl_professionName {
  color: #a7a7a7;
  font-size: 14px;
  font-family: "GillroyRegular";
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hl_bookingAmouns {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-family: "GillroySemiBold";
  align-items: center;
}

.bookingCurrency {
  color: #9cd0fa;
  font-size: 16px;
  font-family: "GillroySemiBold";
  padding-right: 3px;
}

.horiozntalImageWrpr {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: 240px;
  display: flex;
  width: 200px;
}

.horiozntalImageWrpr:hover {
  transform: translate3d(0px, 0px, 0px) scale(1.05);
  transition: all 0.08s ease-in-out 0s;
}

.imgStyle {
  width: 100%;
}

.iconWrprLove {
  position: absolute;
  height: 36px;
  width: 36px;
  background: #1a1a1a;
  top: 16px;
  right: 16px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.amountCurrency {
  display: flex;
  flex-direction: row;
}

.lineStyles1 {
  height: 24px;
  background: #565656;
  width: 2px;
  margin: 0 19px;
}

.timeHr {
  padding-left: 8px;
  font-size: 16px;
  font-family: "GillroySemiBold";
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .horiozntalImageWrpr {
    height: 228px;
    width: 190px;
  }
  .lineStyles1 {
    margin: 0 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .horiozntalImageWrpr {
    height: 198px;
    width: 165px;
  }
  .lineStyles1 {
    margin: 0 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lineStyles1 {
    margin: 0 14px;
  }

  .horiozntalImageWrpr {
    height: 180px;
    width: 150px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .horiozntalImageWrpr {
    height: 180px;
    width: 150px;
  }

  .lineStyles1 {
    margin: 0 12px;
  }
}

@media (max-width: 575px) {
  .horiozntalImageWrpr {
    height: 180px;
    width: 150px;
  }

  .horizontalitem {
    /* min-width: 200px; */
    /* margin-right: 10px; */
    max-width: 150px;
  }

  .lineStyles1 {
    margin: 0 8px;
  }

  .bookingCurrency {
    font-size: 14px;
  }

  .timeHr {
    padding-left: 6px;
    font-size: 14px;
  }

  .amountCurrency {
    font-size: 14px;
  }
}
