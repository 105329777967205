.otp-ver-main-wrapper {
  border-radius: 16px;
  background: #303030;
  box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.02),
    0px 6.650102138519287px 5.32008171081543px 0px rgba(0, 0, 0, 0.03),
    0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.04),
    0px 22.3363094329834px 17.869047164916992px 0px rgba(0, 0, 0, 0.04),
    0px 41.777610778808594px 33.422088623046875px 0px rgba(0, 0, 0, 0.05),
    0px 100px 80px 0px rgba(0, 0, 0, 0.07);
  padding: 35px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.otp-ver-main-head-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
}

.otp-ver-main-head {
  color: var(--primary-sub-head-white-f-8-f-8-f-8, #f8f8f8);
  text-align: center;
  font-family: "GillroySemiBold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px; /* 140.625% */
  letter-spacing: -0.32px;
}

.otp-ver-label-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
}

.otp-ver-label {
  color: var(--primary-heading-off-white-e-1-e-1-e-1, #e1e1e1);
  text-align: center;
  font-family: "GillroySemiBold";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.otp-ver-input {
  border-radius: 4px;
  border: 1px solid var(--input-feild-stroke-565656, #565656);
  background: var(--input-feild-inner-1-a-1-a-1-a, #1a1a1a);
}

.otp-ver-submit-btn-area {
  border-radius: 40px;
  background-color: #0d61d3;
  padding: 8px;
}

.otp-ver-submit-btn-area:hover {
  opacity: 0.7;
}

.otp-ver-btn-label {
  color: #fff;
  text-align: center;
  font-family: "GillroySemiBold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.25px;
}

.otp-ver-resend-link {
  color: #9cd0fa;
  text-align: center;
  font-family: "GillroySemiBold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
}

.otp-ver-resend-link:hover {
  opacity: 0.7;
  color: #9cd0fa;
}

.otpInputStyles {
  border: 1px solid #565656 !important;
  border-radius: 8px !important;
  min-width: 30px !important;
  max-width: 65px !important;
  max-height: 55px !important;
  width: 100% !important;
  height: 100% !important;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 400 !important;
  background: #1a1a1a !important;
}

@media (max-width: 575px) {
  .otp-ver-main-wrapper {
    padding: 15px;
  }

  .otp-ver-main-head {
    font-size: 20px;
  }

  .otp-ver-resend-link {
    font-size: 14px;
  }

  .otp-ver-btn-label {
    font-size: 14px;
  }

  .otp-ver-label {
    font-size: 14px;
  }

  .otpInputStyles {
    font-size: 14px !important;
  }
}
