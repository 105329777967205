.profileHomeContainer {
  background-color: #1a1a1a;
}

.pf-main-top-head {
  color: var(--primary-sub-head-white-f-8-f-8-f-8, #f8f8f8);
  font-family: "GillroySemiBold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -0.36px;
}

.p-c-avatar-imgs {
  width: 100px;
  min-width: 90px;
  min-height: 90px;
  height: 100px;
  overflow: hidden;
  border: 1px solid #565656;
  border-radius: 50%;
}

.pr-home-form-control-basic {
  border-radius: 4px;
  border: 1px solid var(--input-feild-stroke-565656, #565656);
  background: var(--input-feild-inner-1-a-1-a-1-a, #1a1a1a);
  color: #f8f8f8;
}

.pr-home-form-control-basic:hover {
  opacity: 0.85;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.p-c-avatar-imgs-default {
  width: 65px;
  height: 65px;
  overflow: hidden;
}

.p-c-s-s--i-o {
  padding-left: 24px;
}

.p-c-s-s--i-o {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.edit-profile-btns {
  display: flex;
  align-self: center;
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8;
}

.edit-profile-btns:hover {
  opacity: 0.7;
}

.profileCardSecWrpr {
  background: #303030;
  border-radius: 16px;
  padding: 24px 32px;
}

.all-wrpr-cs-pro {
  padding-bottom: 40px;
}

.p-c-i-names {
  font-size: 18px;
  align-items: center;
  letter-spacing: -0.01em;
  font-weight: 700;
  color: #f8f8f8;
  font-family: "GillroySemiBold";
}

.e-p-s-txt {
  /* padding-left: 5px; */
  color: #ffffff;
}

.p-f-inner-value {
  color: var(--primary-sub-head-off-a-7-a-7-a-7, #a7a7a7);
  font-family: "GillroyMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.p-f-inner-value-txt-top {
  color: var(--primary-sub-head-off-a-7-a-7-a-7, #a7a7a7);
  font-family: "GillroyMedium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.p-f-inner-label {
  color: var(--secondary-sub-head-white-fffff, #fff);
  font-family: "GillroyMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.section-semi-head {
  color: #fff;
  font-family: "GillroySemiBold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -0.18px;
}

.p-v-r-p-page {
  padding-top: 16px;
}

.p-v-p-h-txt {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #ffffff;
  font-family: "GillroySemiBold";
  margin-bottom: 24px;
}

.p-v-list-item {
  display: flex;
  width: 200px;
  height: 360px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.play-i-p-v-s {
  position: absolute;
  left: 16px;
  bottom: 16px;
}

.play-i-p-v-s-mute {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.p-v-list-main-wrprs {
  display: flex;
  overflow-x: scroll;
}

.p-v-list-main-wrprs::-webkit-scrollbar {
  display: none;
}

.p-p-user-imgs {
  width: 60%;
}

.play-i-p-v-s-new {
  position: absolute;
}

.n-btn-s-new {
  border: none;
  background: transparent;
}

.link-btn-act {
  font-family: "GillroyMedium";
  font-size: 16px;
  text-decoration: underline !important;
  color: #f8f8f8;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .profileCardSecWrpr {
    padding: 15px;
  }

  .pf-main-top-head {
    font-size: 18px;
  }
  .edit-profile-btns {
    font-size: 12px;
    padding: 6px 12px;
  }
  .p-c-i-names {
    font-size: 16px;
  }

  .section-semi-head {
    font-size: 16px;
    line-height: 24px;
  }

  .p-f-inner-label {
    font-size: 14px;
  }

  .p-f-inner-value {
    font-size: 14px;
  }

  .link-btn-act {
    font-size: 13px;
  }
}
