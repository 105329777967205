.loginContainer {
  background-color: #1a1a1a;
}

.login-card-wrpr {
  background: #303030;
  border-radius: 16px;
  padding: 48px 32px;
}

.login_head {
  font-family: "GillroyBold";
  font-size: 32px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  margin-bottom: 47px;
}

.log-in-btsss {
  background: #0d61d3;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  color: #ffffff;
  margin-top: 24px;
  font-family: "GillroySemiBold";
  width: 100%;
  border: 0;
  height: 52px;
}

.log-in-btsss:hover {
  opacity: 0.7;
}

.log-in-btsError {
  background: "gray";
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  color: #ffffff;
  margin-top: 24px;
  font-family: "GillroySemiBold";
}

.main-logon-inner {
  padding: 100px 0;
}

.line-wrprs-1 {
  height: 1px;
  width: 100%;
  background-color: #484848;
  margin: 56px 0 32px 0;
}

.fbbtns {
  color: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a5a5a5;
  border-radius: 40px;
  padding: 14px 0;
  width: 100%;
  background: none;
}

.fbbtns:hover {
  opacity: 0.7;
}

.s-ss-ssep {
  padding-left: 8px;
  font-family: "GillroySemiBold";
}

.h-24-s {
  height: 24px;
}

.custom-pfa-class-styleq1 {
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  height: 50px;
}

.custom-pfa-class-styleq1:hover {
  opacity: 0.85;
}

.custom-pfa-class-styleq1:disabled {
  opacity: 0.85;
  background-color: #565656;
}

.privacyText {
  color: #ffffff;
  padding: 16px 0;
  font-size: 16px;
  font-family: "GillroyRegular";
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.andSigns {
  padding: 0 5px;
}

.termsOfUse {
  color: #0d61d3;
  font-weight: 700;
}

.termsOfUse:hover {
  opacity: 0.7;
}

.privacyPlolicy {
  color: #0d61d3;
  font-weight: 700;
}

.privacyPlolicy:hover {
  opacity: 0.7;
}

.forgotText {
  color: #ffffff;
  font-family: "GillroyRegular";
}

.forgotText:hover {
  opacity: 0.7;
}

.regBtns {
  color: #ffffff;
  padding-top: 24px;
  font-family: "GillroyRegular";
  display: flex;
  flex-direction: row;
}

.signUpTxt {
  font-family: "GillroySemiBold";
  padding-left: 4px;
}

.log-in-btsError {
  width: 100%;
  border: 0;
  height: 52px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .main-logon-inner {
    padding: 24px 0;
  }
  .login-card-wrpr {
    padding: 40px 16px;
  }
  .line-wrprs-1 {
    margin: 24px 0;
  }

  .login_head {
    font-size: 26px;
    line-height: 24px;
  }

  .privacyText {
    font-size: 14px;
  }

  .fbbtns {
    font-size: 14px;
    padding: 12px 0;
  }
}
