.celebrityContainer {
  background-color: #1a1a1a;
}

.celebrityInnerContainer {
  padding: 40px 0;
}

.h-c-l-scroll-wrps {
  max-width: 200px;
  margin-right: 24px;
}

.how-i-subtitle {
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  font-family: "GillroyRegular";
  margin-bottom: 32px;
}

.how-i-w-headings {
  font-size: 24px;
  line-height: 24px;
  font-family: "GillroyBold";
  letter-spacing: -0.01em;
  color: #f8f8f8;
  margin-bottom: 16px;
}

.sep40 {
  height: 40px;
}

.ssec-c-d-page {
}

.sec-onw-s-c-d {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.section-one-c-d-p {
  display: flex;
  flex-direction: row;
}

.ssec-c-d-page {
  padding-left: 32px;
  flex: 1;
}

.c-d-expla {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #8d8d8d;
  font-family: "GillroyRegular";
}

.blog-c-d {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #e1e1e1;
  font-family: "GillroyMedium";
  margin-bottom: 12px;
}

.cele-name-s {
  font-size: 23px;
  line-height: 42px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroyBold";
}

.ratingReview {
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.reviewCounts {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  padding-left: 4px;
  font-family: "GillroyRegular";
}

.cl-det-av-rating-label {
  color: var(--primary-sub-head-off-a-7-a-7-a-7, #f8f8f8);
  font-family: "GillroySemiBold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px; /* 281.25% */
  letter-spacing: -0.16px;
}

.availabilityTiming {
  display: flex;
  align-items: center;
  background: #242424;
  border-radius: 8px;
  padding: 10px 16px;
}

.attext {
  padding-left: 8px;
  font-family: "GillroySemiBold";
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #a7a7a7;
}

.section-one-c-d-p {
  border-bottom: 0.75px solid #414141;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.bb-b-f-d-f {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.b-n-bts-c-d {
  background: #0d61d3;
  border-radius: 40px;
  color: #fff;
  padding: 12px 40px;
  font-family: "GillroySemiBold";
  border: 1px solid #0d61d3;
}

.b-n-bts-c-d:hover {
  opacity: 0.7;
}

.b-n-bts-c-d-b-b {
  border: 2px solid #0d61d3;
  border-radius: 40px;
  padding: 12px 40px;
  font-family: "GillroySemiBold";
  color: #fff;
  margin-left: 12px;
  background-color: transparent;
}

.b-n-bts-c-d-b-b:hover {
  opacity: 0.7;
}

.r-p-imgs {
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
  min-width: 200px;
  -webkit-transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.cel-cat-text {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "GillroySemiBold";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  letter-spacing: -0.25px;
}

.cel-cat-main-div {
  border-radius: 40px;
  border: 1px solid #374957;
  margin-right: 16px;
  padding: 15px;
}

.cel-cat-sup-div {
  overflow-x: scroll;
}

.cel-cat-sup-div::-webkit-scrollbar {
  display: none;
}

.cel-cat-main-div:hover {
  opacity: 0.7;
}

.r-p-inners-wrpr {
  padding-bottom: 40px;
}

.mute-wrprs-c-d {
  position: absolute;
  bottom: 26px;
  right: 18px;
}

.mute-wrprs-c-d-play {
  position: absolute;
  left: 18px;
  bottom: 26px;
}

.reviews-wrprs-i {
  border-top: 0.75px solid #414141;
  margin-bottom: 32px;
  padding: 32px 0;
  border-bottom: 0.75px solid #414141;
}

.r-img-wrpr-ii-c {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #24a0ff;
  font-family: "GillroySemiBold";
  font-size: 14px;
}

.re-u-name {
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  font-family: "GillroySemiBold";
  margin-bottom: 4px;
}

.re-u-name-time {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #565656;
  font-family: "GillroyMedium";
}

.review-para-cc {
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  font-family: "GillroyRegular";
  font-size: 14px;
  margin-bottom: 18px;
}
.review-para-cc1 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  font-family: "GillroyRegular";
  font-size: 14px;
  /* min-height: 130px; */
  /* max-height: 130px; */
  overflow: hidden;
  margin-bottom: 18px;
}

.see-more-u-txt {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  font-family: "GillroyRegular";
}

.wrpr-all-i-c-s {
  display: flex;
}

.review-ss-wrpr-ii {
  padding-left: 8px;
}

.show-all-wrpsss {
  display: flex;
  background: transparent;
  border: none;
}

.show-all-reviews {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01618em;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 12px 24px;
  font-family: "GillroyMedium";
}

.show-all-reviews:hover {
  opacity: 0.7;
}

.spinnerWrpr {
  display: flex;
  justify-content: center;
  padding: 200px 0;
}

.-an-r-v-wrprs {
  display: flex;
  overflow-x: scroll;
}

.-an-r-v-wrprs::-webkit-scrollbar {
  display: none;
}

.modal-wrpr-body::-webkit-scrollbar {
  display: none;
}

.-an-r-v-play-btn {
  background: none;
  border: none;
}

.-an-c-d-muts-bts-1 {
  background: none;
  border: none;
}
.no-review-txt {
  color: rgba(249, 249, 249, 0.61);
  margin-top: 16px;
}

.modal-wrpr-body.modal-body {
  background: #2e2e2e;
}

.close-btn-wrpr-modal {
  background: transparent;
  border: 0;
  margin-bottom: 24px;
}

.modal-content {
  background-color: #2e2e2e;
}

.m-cls-btn-style {
  font-size: 32px;
  color: #656565;
}

.total-review-modals-count {
  font-size: 24px;
  line-height: 45px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroySemiBold";
  margin-bottom: 24px;
}

.c-d-n-a-txt {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroyRegular";
}

.c-d-not-resp-txt {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroyRegular";
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h-c-l-scroll-wrps {
    max-width: 190px;
    margin-right: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h-c-l-scroll-wrps {
    max-width: 165px;
    margin-right: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h-c-l-scroll-wrps {
    max-width: 150px;
    margin-right: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-one-c-d-p {
    flex-direction: column;
  }
  .ssec-c-d-page {
    padding-left: 0;
  }
  .cel-cat-main-div {
    margin-right: 13px;
    padding: 15px;
  }
  .h-c-l-scroll-wrps {
    max-width: 150px;
    margin-right: 16px;
  }
}
@media (max-width: 575px) {
  .section-one-c-d-p {
    flex-direction: column;
  }

  .cel-cat-main-div {
    margin-right: 10px;
    padding: 12px;
  }

  .ssec-c-d-page {
    padding-left: 0;
  }
  .b-n-bts-c-d {
    padding: 12px;
    font-size: 12px;
  }
  .bb-b-f-d-f {
    flex-direction: row;
    align-items: flex-start;
  }
  .b-n-bts-c-d-b-b {
    padding: 12px;
    font-size: 12px;
  }

  .cel-cat-text {
    font-size: 14px;
    line-height: 20px;
  }

  .h-c-l-scroll-wrps {
    max-width: 150px;
    margin-right: 16px;
  }

  .cele-name-s {
    font-size: 18px;
    line-height: 36px;
  }

  .blog-c-d {
    font-size: 14px;
    line-height: 14px;
  }

  .c-d-expla {
    font-size: 14px;
    line-height: 22px;
  }

  .celebrityInnerContainer {
    padding: 10px 0;
  }

  .cl-det-av-rating-label {
    font-size: 14px;
    line-height: 32px;
  }

  .reviewCounts {
    font-size: 14px;
    line-height: 14px;
  }

  .attext {
    padding-left: 5px;
    font-size: 13px;
    line-height: 13px;
  }

  .how-i-w-headings {
    font-size: 16px;
    line-height: 16px;
  }

  .review-para-cc {
    line-height: 22px;
    font-size: 14px;
  }

  .show-all-reviews {
    font-size: 14px;
    line-height: 12px;
    padding: 10px 20px;
  }
  .total-review-modals-count {
    font-size: 16px;
    line-height: 26px;
  }
  .c-d-n-a-txt {
    font-size: 14px;
    line-height: 26px;
  }
  .c-d-not-resp-txt {
    font-size: 13px;
    line-height: 16px;
  }
}

/* Fold Phone */
@media (max-width: 280px) {
  .cele-name-s {
    font-size: 18px;
  }

  .blog-c-d {
    font-size: 12px;
  }

  .c-d-expla {
    font-size: 12px;
    line-height: 20px;
  }
  .c-d-n-a-txt {
    font-size: 12px;
    line-height: 20px;
  }
}
