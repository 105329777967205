.enrollBtn {
  font-family: "GillroyMedium";
  width: 175px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  justify-content: center;
  display: flex;
  color: #ffffff;
}

.enrollBtn:hover {
  opacity: 0.7;
}

.footerText {
  font-family: "GillroyRegular";
  color: #ffffff;
  line-height: 21px;
  margin-bottom: 15px;
  font-size: 16px;
}

.footer-success-msg {
  font-family: "GillroyRegular";
  color: #ffffff;
  line-height: 21px;
  margin-bottom: 15px;
  font-size: 16px;
  opacity: 0.6;
}

.footer-input-grp {
  width: 75%;
}

.footerBottomTxt {
  font-family: "GillroyRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.6;
  justify-content: flex-end;
}

.flex-s-sa-an {
  display: flex;
  justify-content: flex-end;
}

.enrolBtnWrpr {
  display: flex;
  justify-content: flex-end;
}

.footerList {
  flex-direction: row;
  display: flex;
  gap: 12px;
}

.footer-ul-li-styles {
  color: #ffffff;
  font-family: "GillroySemiBold";
  margin-right: 25px;
  font-size: 18px;
}

.footer-ul-li-styles:hover {
  opacity: 0.7;
}

.news-letter-input {
  background: rgba(255, 255, 255, 0.05);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  border-right: 0;
  padding: 10px;
  color: #ffffff !important;
}

.news-letter-input:hover {
  opacity: 0.7;
}

.news-letter-input:focus {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  color: #ffffff;
}

.news-letter-btn {
  background: #0d61d3;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  padding: 10px;
  border-radius: 0%;
  color: #ffffff !important;
  font-family: "GillroyRegular";
  font-weight: 500;
}

.news-letter-btn:hover {
  opacity: 0.7;
}

.footer-newsletter-head {
  font-family: "GillroySemiBold";
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-ul-li-styles {
    margin-right: 18px;
    font-size: 16px;
  }
  .footer-input-grp {
    width: 80%;
  }
  .footerList {
    gap: 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-ul-li-styles {
    margin-right: 12px;
    font-size: 16px;
  }
  .footer-input-grp {
    width: 100%;
  }

  .footerList {
    gap: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flex-s-sa-an {
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
  }
  .enrolBtnWrpr {
    justify-content: flex-start;
  }

  .footerBottomTxt {
    justify-content: flex-start;
  }

  .footer-ul-li-styles {
    margin-right: 20px;
  }
}

@media (max-width: 575px) {
  .flex-s-sa-an {
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
  }

  .enrolBtnWrpr {
    justify-content: flex-start;
  }

  .footerBottomTxt {
    justify-content: flex-start;
  }

  .footerList {
    flex-direction: column;
  }

  .footer-ul-li-styles {
    margin-right: 10px;
    flex-wrap: wrap;
    font-size: 18px;
  }

  .footer-input-grp {
    width: 100%;
  }

  .footer-success-msg {
    line-height: 18px;
    font-size: 13px;
  }

  .footerBottomTxt {
    font-size: 13px;
  }

  .footer-newsletter-head {
    margin-bottom: 12px;
    font-size: 18px;
  }

  .footerText {
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }
}
