.privacyContainer {
  background-color: #1a1a1a;
}

h1 .privacyhead {
  font-size: 26px;
}

p {
  color: #fff;
}

h1 {
  color: #fff;
}

h2 {
  color: #fff;
}
h3 {
  color: #fff;
}

ul {
  color: #fff;
}
