.mainContainer {
  flex: 1;
  background-color: #1a1a1a;
}

.successCardWrpr {
  background-color: #303030;
  border-radius: 16px;
  justify-content: center;
  display: flex;
  padding: 80px 40px;
  flex-wrap: wrap;
  flex-direction: column;
}

.cardAllWrpr {
  padding: 150px 0;
}

.img_s_p_wrprs {
  display: flex;
  margin-bottom: 25px;
}

.ps_txts {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.32px;
  color: #fcfcfc;
  margin-bottom: 6px;
  font-family: "GillroyBold";
}

.orderIDs {
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #a7a7a7;
  font-family: "GillroyMedium";
  margin-bottom: 20px;
}

.desSuccessPage {
  text-align: center;
  color: #e5e5e5;
  font-family: "GillroyMedium";
  margin-bottom: 36px;
  letter-spacing: -0.02em;
}

.s_p_btn {
  text-align: center;
  align-self: center;
  background: #0d61d3;
  padding: 10px 32px;
  border-radius: 40px;
  color: #ffffff;
  font-family: "GillroySemiBold";
}

.linkGoBtn {
  align-self: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cardAllWrpr {
    padding: 40px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cardAllWrpr {
    padding: 40px 0;
  }
}
@media (max-width: 575px) {
  .cardAllWrpr {
    padding: 40px 0;
  }
  .successCardWrpr {
    padding: 40px 16px;
  }
  .ps_txts {
    font-size: 18px;
    line-height: 24px;
  }

  .desSuccessPage {
    margin-bottom: 24px;
    font-size: 14px;
  }
  .s_p_btn {
    padding: 12px 24px;
    font-size: 14px;
  }
}
