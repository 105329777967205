.privacyContainer {
  background-color: #1a1a1a;
}

.main-wrapper {
  padding: 150px 300px;
}

.submit-btn {
  padding: 15px 30px;
  text-align: center;
  border-radius: 40px;
  background: var(--alsafar-secondary-colour, #0d61d3);
}

.submit-btn-text {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Gilroy";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.25px;
}

.main-head {
  color: #0d61d3;
  text-align: center;
  font-family: "Yuji Boku";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.64px;
}

.sub-head {
  color: var(--primary--Sub-head--white-F8F8F8, #f8f8f8);
  text-align: center;
  font-family: "Gilroy";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 187.5% */
  letter-spacing: -0.24px;
}

.desc-text {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-wrapper {
    padding: 120px 240px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-wrapper {
    padding: 90px 180px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-wrapper {
    padding: 60px 120px;
  }
}
@media (max-width: 575px) {
  .main-wrapper {
    padding: 30px 60px;
  }
  .sub-head {
    font-size: 16px;
  }
  .main-head {
    font-size: 36px;
  }
  .desc-text {
    font-size: 13px;
  }
  .submit-btn {
    padding: 10px 20px;
  }
  .submit-btn-text {
    font-size: 13px;
  }
}
