.bookingContainer {
  flex: 1;
  background-color: #1a1a1a;
}

.errorBoarder {
  border-color: #ef5537 !important;
}

.c-img-wrpr {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.whosName {
  font-size: 18px;
  color: #f8f8f8;
  font-family: "GillroyBold";
  padding-left: 20px;
  flex: 1;
}

.c-f-s-wrprs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lineStyles {
  width: 100%;
  background: #414141;
  height: 1px;
  margin: 24px 0;
}

.pfa-a-heading {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 16px;
  font-family: "GillroySemiBold";
}

.pfa-f-i-round {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.pfa-f-i-s-img.active {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(234, 40, 30, 0.1);
  border: 1px solid #ea281e;
  padding: 10px;
}

.pfa-f-i-s-img {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: 0.2rem solid rgb(65, 61, 64);
  padding: 10px;
}

.pfs-ss-b-row {
  display: flex;
  flex-direction: row;
}

.forme-wrpr-row {
  display: flex;
  align-items: center;
  padding-top: 8px;
  text-align: center;
  justify-content: center;
}

.for-me-name.active {
  color: #f3f3f3;
}

.for-me-name {
  font-size: 13px;
  text-align: center;
  color: #a7a7a7;
  font-family: "GillroySemiBold";
}

.f-i-wrpr-all {
  width: 64px;
  margin-right: 32px;
}

.sep-line {
  width: 32px;
}

.pfa-a-heading1 {
  color: #ffffff;
  font-size: 16px;
  font-family: "GillroySemiBold";
  line-height: 16px;
  margin-bottom: 16px;
}

.pfa-subtitles {
  color: #a7a7a7;
  opacity: 0.5;
  font-size: 14px;
  font-family: "GillroyMedium";
}

.sepinput-size {
  height: 16px;
}

.sepinput-size1 {
  height: 28px;
}

.sepinput-size12 {
  height: 18px;
}

.sepinput-size2 {
  width: 12px;
}

input.custom-pfa-class-style {
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  height: 55px;
  font-size: 16px;
}

input.custom-pfa-class-style:disabled {
  background: #1a1a1a;
}

input.custom-pfa-class-style:hover {
  opacity: 0.85;
}

.bbInsND {
  font-family: "GillroyRegular";
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #e1e1e1;
  margin-top: 6px;
  color: #ef5537;
}

.names-styles.active {
  color: #ffffff;
}

.chip-names.active {
  background: #0d61d3;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #3d3d3d;
}

.chip-names:hover {
  opacity: 0.7;
}

.chips-wrpr {
  display: flex;
  flex-direction: row;
}

.chip-names {
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #3d3d3d;
  background: linear-gradient(
      180deg,
      rgba(151, 151, 151, 0.2) 0%,
      rgba(49, 49, 49, 0) 100%
    ),
    #1a1a1a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  margin-right: 8px;
}

.names-styles {
  color: #e1e1e1;
  font-size: 13px;
  font-family: "GillroySemiBold";
}

.custom-pfa-class-style-multi {
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
}

.custom-pfa-class-style-multi:hover {
  opacity: 0.85;
}

.custom-pfa-class-style-multi::-webkit-scrollbar {
  display: none;
}

.sep-32-custom {
  height: 32px;
}

.cehckbox-custom input {
  height: 32px;
  width: 32px;
  background: #1a1a1a;
  border: 1px solid #4a4a4a;
  cursor: pointer;
}

.arrowBack:hover {
  opacity: 0.7;
}

.cehckbox-custom input:hover {
  opacity: 0.85;
}

.hide-wrpr {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroyMedium";
  padding-left: 12px;
}

.continue-btns {
  color: #ffffff;
  background: #0d61d3;
  border-radius: 40px;
  display: flex;
  padding: 18px 70px;
  justify-content: center;
  border: 0;
  width: 100%;
}

.-an-w-t-f-btns {
  background: none;
  border: none;
}

.-an-w-t-f-btns:hover {
  opacity: 0.7;
}

.form-select-custm-class {
  background-color: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  height: 50px;
  font-size: 16px;
}

.form-select-custm-class:hover {
  opacity: 0.85;
}

select.form-select-custm-class.form-select {
  box-shadow: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  input.custom-pfa-class-style {
    height: 42px;
    font-size: 13px;
  }

  .custom-pfa-class-style-multi {
    font-size: 13px;
  }

  .form-select-custm-class {
    height: 42px;
    font-size: 13px;
  }

  .hide-wrpr {
    font-size: 13px;
    line-height: 14px;
    padding-left: 6px;
  }

  .cehckbox-custom input {
    height: 22px;
    width: 22px;
    margin-top: 0;
  }

  .pfa-a-heading1 {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 9px;
  }

  .lineStyles {
    margin: 16px 0;
  }

  .sepinput-size1 {
    height: 20px;
  }

  .sepinput-size12 {
    height: 14px;
  }

  .bbInsND {
    font-size: 11px;
  }
}
