.viewAllHeading {
  font-family: "GillroyBold";
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  margin-bottom: 8px;
}

.subtitle_head {
  font-family: "GillroyRegular";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
}

.separatorLine {
  background: #414141;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 26px;
}

.mnCtDaLs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(202px, auto));
  gap: 32px 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(210px, auto));
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .viewAllHeading {
    font-size: 26px;
    line-height: 26px;
  }
  .subtitle_head {
    font-size: 15px;
    line-height: 15px;
  }
  .separatorLine {
    margin-top: 16px;
    margin-bottom: 22px;
  }
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(174px, auto));
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .viewAllHeading {
    font-size: 24px;
    line-height: 24px;
  }
  .subtitle_head {
    font-size: 15px;
    line-height: 15px;
  }
  .separatorLine {
    margin-top: 16px;
    margin-bottom: 20px;
  }
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(162px, auto));
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .viewAllHeading {
    font-size: 22px;
    line-height: 22px;
  }
  .subtitle_head {
    font-size: 14px;
    line-height: 14px;
  }
  .separatorLine {
    margin-top: 14px;
    margin-bottom: 18px;
  }
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(145px, auto));
  }
}

@media only screen and (min-width: 451px) and (max-width: 575px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(161px, auto));
  }
}

@media only screen and (min-width: 428px) and (max-width: 450px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(195px, auto));
  }
}

@media only screen and (min-width: 400px) and (max-width: 427px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(186px, auto));
  }
}

@media only screen and (min-width: 381px) and (max-width: 399px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(167px, auto));
  }
}

@media only screen and (min-width: 370px) and (max-width: 380px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(167px, auto));
  }
}

@media only screen and (min-width: 358px) and (max-width: 369px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(156px, auto));
  }
}

@media only screen and (min-width: 351px) and (max-width: 357px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(156px, auto));
  }
}

@media only screen and (min-width: 331px) and (max-width: 350px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(147px, auto));
  }
}

@media (max-width: 330px) {
  .mnCtDaLs {
    grid-template-columns: repeat(auto-fill, minmax(140px, auto));
  }
}

@media (max-width: 575px) {
  .viewAllHeading {
    font-size: 18px;
    line-height: 18px;
  }
  .subtitle_head {
    font-size: 14px;
    line-height: 14px;
  }
  .separatorLine {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}
