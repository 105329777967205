.privacyContainer {
  background-color: #1a1a1a;
  font-size: 12px;
}

.privacyContainer p {
  color: #ffffffe5;
}

.privacyContainer h1 {
  color: #ffffffe5;
  font-size: 20px;
}

.privacyContainer h2 {
  color: #ffffffe5;
  font-size: 16px;
}

.privacyContainer h3 {
  color: #ffffffe5;
  font-size: 15px;
}

.privacyContainer ul {
  color: #ffffffe5;
}
