.paymentContainer {
  flex: 1;
  background-color: #1a1a1a;
}

.newCardWrpsPay {
  background: #303030;
  padding: 48px 0;
  border-radius: 16px;
}

.payment-main-all-wrpr {
  padding: 100px 0;
}

.payContainer button {
  background-color: #1a1a1a !important;
}

.payContainer p {
  color: #f8f8f8 !important;
}

.paypal-button.paypal-button-color-black {
  background-color: #1a1a1a !important;
}

.overlayDiv {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}

.paymentCancelBtn {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;
  font-family: "GillroySemiBold";
}

.radio-btn-sections {
  display: flex;
  flex-direction: row;
}

.pay-card-heading {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;
  font-family: "GillroySemiBold";
  padding-bottom: 24px;
}
.sec-pat-ss-ll {
  font-size: 16px;
  line-height: 16px;
  color: #f8f8f8;
  font-family: "GillroySemiBold";
  padding-bottom: 4px;
}

.sec-pay-ll {
  padding-left: 12px;
}

.ss-pay-days {
  font-family: "GillroyRegular";
  font-size: 12px;
  line-height: 12px;
  color: #a7a7a7;
}

.p-16s {
  height: 16px;
}

.sep-line-1 {
  height: 1px;
  background-color: #414141;
  width: 100%;
  margin: 32px 0;
}

.sep-line-12 {
  height: 1px;
  background-color: #414141;
  width: 100%;
  margin: 0px 0 16px 0;
}

.pay-card-heading1 {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;
  font-family: "GillroySemiBold";
  padding-top: 40px;
  padding-bottom: 12px;
}

.summary-names {
  font-size: 16px;
  line-height: 15px;
  color: #ffffff;
  font-family: "GillroySemiBold";
}

.summary-wrprs {
  display: flex;
  justify-content: space-between;
}

.-btn-books-now {
  background: #0d61d3;
  border-radius: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  width: 100%;
  border: 0;
  height: 48px;
}

.h-50s {
  height: 50px;
}

.-paddi16 {
  padding: 48px;
}

.sep-btn-p {
  height: 32px;
}
.or-line-payment {
  height: 2px;
  padding: 32px 0;
  position: relative;
}

.or-line-payment-or {
  color: #fff;
  background: #303030;
  padding: 0 20px;
}

.or-line-payment-or-abs {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .payment-main-all-wrpr {
    padding: 40px 0;
  }
  .h-50s {
    height: 32px;
  }

  .-paddi16 {
    padding: 40px 16px;
  }

  .paymentCancelBtn {
    font-size: 14px;
    line-height: 16px;
  }

  .pay-card-heading {
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 16px;
  }

  .pay-card-heading1 {
    font-size: 16px;
    line-height: 16px;
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .summary-names {
    font-size: 14px;
    line-height: 10px;
  }

  .sec-pat-ss-ll {
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 4px;
  }
}
