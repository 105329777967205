/* MY STYLES START HERE */
.nav-desk-menu {
  width: 100%;
  padding: 0 0.25rm;
  align-items: center;
}

.nav-mob-menu {
  width: 100%;
  padding: 0 0.25rm;
  align-items: center;
  display: none;
}

.nv-logo-style {
  width: 140px;
}

.mn-mu-dv-vi {
  position: absolute;
  background-color: #1a1a1a;
  padding: 20px;
  z-index: 120;
  list-style: none;
  min-width: 75%;
  min-height: 50%;
  top: 0px;
  max-width: 100%;
}

/* OLD STYLES START HERE */
.headingWrprs {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 0;
}

.headingFirstSection {
  display: flex;
  align-items: center;
}

.nav_ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 32px;
  align-items: center;
}

.nav_ui_li {
  font-size: 16px;
  color: #ffffff;
  margin-right: 16px;
  padding: 12px;
  border-radius: 16px;
}

.nav_ui_li:hover {
  background: #282828;
}

.nav_ui_li.active {
  background: #282828;
}

.loginTextMobileScreen {
  font-weight: 500;
  line-height: 0.1px;
  font-size: 16px;
  font-family: "GillroySemiBold";
  color: #fff;
}

.loginTextMobileScreen:hover {
  opacity: 0.7;
}

.barsStyles {
  color: #fff;
  cursor: pointer;
}

.barsStyles:hover {
  opacity: 0.7;
}

.loginBtns {
  color: #fff;
  padding: 8px 24px;
  border: 1px solid #565656;
  border-radius: 8px;
  font-size: 14px;
  font-family: "GillroyMedium";
}

.loginBtns:hover {
  opacity: 0.7;
}

.secondSecNav {
  display: flex;
  align-items: center;
}

.searchSeparator {
  width: 40px;
}

.search-input-custom-class {
  background: #242424;
  border: none;
  border-radius: 0;
  color: #ffffff;
  font-size: 16px;
}

.search-btn-close {
  width: 6px !important;
  height: 6px !important;
  background-size: auto;
  margin-top: 12px;
}

.search-btn-close:focus {
  box-shadow: none;
  opacity: 1;
}

.searchWrpr {
  /* display: flex; */
  /* align-items: center; */
  background: #242424;
  border: 1px solid #2e2e2e;
  border-radius: 8px;
  padding: 0 24px;
  min-width: 400px;
  max-width: 100%;
}

.searchWrpr:hover {
  opacity: 0.85;
}

.logo-All-Wrpr {
  background: none;
  border: 0;
  padding-right: 20px;
}

.logo-All-Wrpr {
  display: none;
}

.custm-avatar-wrpr {
  background: #1a1a1a;
  border: none;
  padding: 0;
}

.i-avatar-header {
  display: flex;
  align-items: center;
}

.dropdown-toggle::after {
  display: none;
}
/* .dropdown-toggle:active {
  background: #1a1a1a;
} */

.dropdown-menu-elem {
  color: #fff;
  padding: 10px;
}

.dropdown-menu-elem:hover {
  color: #fff;
  background-color: #1a1a1a;
}

.user-pro-container {
  width: 45px;
  background: #565656;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.user-pro-container:hover {
  opacity: 0.7;
}

button.custm-avatar-wrpr:hover {
  background-color: #1a1a1a;
}

button.custm-avatar-wrpr::selection {
  background-color: #1a1a1a;
}

.search-dropdown-all-container {
  position: relative;
}

.search-dropdown-container {
  max-height: 50rem;
  min-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  top: 40px;
  right: 0;
  position: absolute;
  background-color: #282828;
  z-index: 100;
  border-radius: 8px;
  display: block;
  padding-bottom: 15px;
  padding-top: 15px;
  min-width: 400px;
  max-width: 100%;
}

.search-dropdown-container::-webkit-scrollbar {
  display: none;
}

.search-dropdown-items {
  padding: 10px 20px;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  display: flex;
  align-items: center;
}

.search-dropdown-items:hover {
  opacity: 0.7;
}

.search-input-custom-class.form-control:focus {
  background-color: #242424 !important;
}
.search-cel-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
}

.search-result-names {
  margin-left: 12px;
  font-family: "GillroySemiBold";
  color: #f1f1f1;
}

.s-d-d-i-name-intro {
  font-size: 12px;
  font-family: "GillroyRegular";
  color: #f1f1f1;
}

.nav_ui_li a {
  font-size: 16px;
  color: #ffffff;
  margin-right: 16px;
}

.searchbutton {
  height: 58px;
  width: 58px;
  border: none;
  background: #565656;
  border-radius: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo-All-Wrpr {
    display: block;
  }

  .nv-logo-style {
    width: 130px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .logo-All-Wrpr {
    display: block;
  }
  .nv-logo-style {
    width: 110px;
  }
}
@media (max-width: 575px) {
  .logo-All-Wrpr {
    display: block;
  }
  .nv-logo-style {
    width: 90px;
  }

  .nav_ui_li {
    font-size: 13px;
  }

  .nav_ui_li a {
    font-size: 13px;
  }

  .loginTextMobileScreen {
    font-size: 13x;
  }

  .loginBtns {
    font-size: 13px;
  }
}

@media only screen and (max-width: 992px) {
  .nav-desk-menu {
    display: none;
  }

  .nav-mob-menu {
    display: block;
  }

  .user-pro-container {
    width: 40px;
    height: 40px;
  }

  .search-dropdown-container {
    max-height: 50rem;
    min-height: 300px;
    left: -4px;
    margin-left: 15px;
    margin-right: 10px;
    min-width: 100px;
  }

  .searchWrpr {
    min-width: 100px;
  }

  .headingWrprs {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 0;
    flex: 1;
  }
}

@media (max-width: 575px) {
  .search-input-custom-class {
    font-size: 14px;
  }

  .search-btn-close {
    margin-top: 10px;
    width: 4px !important;
    height: 4px !important;
  }
}
