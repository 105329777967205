.home-page-heads {
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroyBold";
}

.home-page-subheads-i {
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  font-family: "GillroyRegular";
}

.categiries-wors-scroll {
  display: flex;
  overflow-x: scroll;
}

.categoriesWrpr-mains {
  padding: 40px 56px;
  background: #3bc4c3;
  border-radius: 24px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroySemiBold";
  margin-right: 32px;
}

.categoriesWrpr-mains:hover {
  opacity: 0.7;
}

.categories-wrpr-h-main {
  padding-bottom: 70px;
}

.viewAllButton {
  background: none;
  color: #ffffff;
  border: none;
  padding: 0;
  font-family: "GillroySemiBold";
  text-decoration: underline;
  font-size: 16px;
}

.viewAllButton:hover {
  opacity: 0.7;
}

.-an-main-header-wrpr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-page-heads {
    font-size: 24px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-page-heads {
    font-size: 22px;
    line-height: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-page-heads {
    font-size: 20px;
    line-height: 20px;
  }
}
@media (max-width: 575px) {
  .home-page-heads {
    font-size: 18px;
    line-height: 20px;
  }
  .-an-main-header-wrpr {
    margin-bottom: 16px;
  }
  .viewAllButton {
    font-size: 13px;
  }
}
