.hit-card-wrpr {
    background-color: rgba(46, 46, 46, 0.73);
    padding: 32px 21px;
    border-radius: 12px;
}

.hiw-heading {
    font-size: 18px;
    color: #FFFFFF;
    font-family: 'GillroySemiBold';
    line-height: 28px;
    margin-bottom: 12px;
}

.hiw-para {
    line-height: 24px;
    color: #F8F8F8;
    opacity: 0.7;
    font-size: 16px;
}

.hit-main-wrpr{
    display: flex;
    flex-direction: row;
}

