.cNNst {
  color: #f8f8f8;
  font-size: 16px;
  font-family: "GillroyMedium";
  margin-top: 16px;
}

.jMSkFv {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  aspect-ratio: 5 / 6;
  transition: filter 100ms ease-in 0s;
}

.bjNQhL {
  appearance: none;
  background-color: transparent;
  border: none;
  color: rgb(242, 241, 243);
  margin: 0px;
  padding: 0px;
}

.cIntTx {
  color: #a7a7a7;
  font-size: 14px;
  font-family: "GillroyRegular";
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gqtDFW {
  appearance: none;
  background-color: transparent;
  border: none;
  color: rgb(242, 241, 243);
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.bkAdi {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-family: "GillroySemiBold";
  align-items: center;
}

.bKCy {
  color: #9cd0fa;
  font-size: 16px;
  font-family: "GillroySemiBold";
  padding-right: 3px;
}

.hrIWN {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
}

.hrIWN:hover {
  transform: translate3d(0px, 0px, 0px) scale(1.05);
  transition: all 0.08s ease-in-out 0s;
}

.imgStyle {
  width: 100%;
}

.iconWrprLoveNew {
  position: absolute;
  height: 36px;
  width: 36px;
  background: #1a1a1a;
  top: 16px;
  right: 16px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.amCNw {
  display: flex;
  flex-direction: row;
}

.lineStylesNew {
  height: 24px;
  background: #565656;
  width: 2px;
  margin: 0 19px;
}

.timeHrNew {
  padding-left: 8px;
  font-size: 16px;
  font-family: "GillroySemiBold";
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lineStylesNew {
    margin: 0 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lineStylesNew {
    margin: 0 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lineStylesNew {
    margin: 0 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lineStylesNew {
    margin: 0 12px;
  }
}

@media (max-width: 575px) {
  .lineStylesNew {
    margin: 0 8px;
  }

  .bKCy {
    font-size: 14px;
  }

  .timeHrNew {
    padding-left: 6px;
    font-size: 14px;
  }

  .amCNw {
    font-size: 14px;
  }
}
