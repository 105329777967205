.containerO-wr {
  background-color: #1a1a1a;
}

.order-inner-wrpr {
  padding: 20px 0;
}

.reviewSubmitWrpr {
  background: #303030;
  padding: 16px;
  border-radius: 16px;
}

.ratingContainer {
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.r-v-v-txt {
  color: #ffffff;
  font-family: "GillroySemiBold";
  padding-right: 12px;
}

.review-profile-sec {
  padding-block: 11px;
}
.r-p-s-f-sec {
  height: 64px;
  width: 64px;
  overflow: hidden;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.review-profile-sec {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #414141;
}

.r-p-s-f-secs {
  flex: 1;
  padding-left: 16px;
  color: #ffffff;
  font-family: "GillroySemiBold";
}

.review-t-v-txts {
  color: #ffffff;
  font-family: "GillroySemiBold";
  padding-bottom: 16px;
}

.-an-c-c-form {
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
}

.-an-c-c-form:hover {
  opacity: 0.85;
}

.reviewInput {
  padding: 12px 0 0 0;
}

.button-s-wrrprs {
  display: flex;
  justify-content: flex-end;
  padding-top: 17px;
}

.submit-bts-o-i-p {
  background: #0d6efd;
  border-radius: 40px;
  border: 0;
  padding: 16px 32px;
  color: #ffffff;
  font-family: "GillroySemiBold";
  width: 120px;
}

.submit-bts-o-i-p:hover {
  opacity: 0.7;
}

.order-heads-head {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroyBold";
}

.r-p-imgs-new {
  border-radius: 15px;
  align-items: center;
  position: relative;
  margin-right: 10px;
  overflow: hidden;
  min-width: 220px;
  -webkit-transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.p-s-img-wrpr {
  width: 215px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 337px;
  position: relative;
}

.pro-sec-wrprs {
  display: flex;
  flex-direction: row;
}

.pro-sec-wrprs {
  padding-block: 30px;
}

/* .sec-sec-wrrprs {
  padding-left: 30px;
} */

.orderIds {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.25px;
  color: #0d61d3;
  font-family: "GillroyRegular";
}

.names-cels {
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-family: "GillroySemiBold";
  color: #ffffff;
  margin-bottom: 6px;
}

.cel-pro {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #a0a3bd;
  font-family: "GillroyMedium";
  margin-bottom: 12px;
}

.cel-sec-s {
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  font-family: "GillroyRegular";
  margin-bottom: 31px;
  max-width: 350px;
}

.downloadbtsn-ss-s {
  background: #0d61d3;
  border-radius: 40px;
  border: 0;
  padding: 16px 40px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #ffffff;
  font-family: "GillroySemiBold";
  margin-right: 12px;
}

.downloadbtsn-ss-s:hover {
  opacity: 0.7;
}

.report-btns-ss {
  border: 0;
  padding: 16px 40px;
  border-radius: 40px;
  border: 1px solid #0d61d3;
  background-color: transparent;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #0d61d3;
  font-family: "GillroySemiBold";
}

.report-btns-ss:hover {
  opacity: 0.7;
}

.pauseAndPlayIcons {
  position: absolute;
  left: 16px;
  bottom: 16px;
}

.-an-c-d-muts-bts {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

/* videoPlayer */

.v-p-container {
  background-color: #1a1a1a;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh);
}

.v-p-inner-container {
  /* padding: 80px 0; */
}

.video-p-wrpr {
  width: 45vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.v-d-cel-img-wrpr {
  width: 40px;
  height: 40px;
  position: absolute;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  overflow-x: hidden;
  overflow-y: hidden;
  bottom: 60px;
  right: 16px;
}

.downloadBtns-v-d {
  width: 40px;
  height: 40px;
  background: #e1e1e1;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 116px;
}
.comment-texts-caa {
  color: #ffffff;
}

.statusDetailsText {
  font-size: 14px;
  font-family: "GillroyRegular";
  line-height: 18px;
  margin: 16px 0;
  color: #f8f8f8;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .pro-sec-wrprs {
    flex-direction: column;
  }
  /* .sec-sec-wrrprs {
    padding: 16px 0 0 0;
  } */
  .order-inner-wrpr {
    padding: 15px 0;
  }
  .video-p-wrpr {
    width: 90%;
  }

  .order-heads-head {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .downloadbtsn-ss-s {
    padding: 12px 24px;
    font-size: 14px;
    /* line-height: 15px; */
  }

  .report-btns-ss {
    padding: 15px 30px;
    font-size: 14px;
    line-height: 15px;
  }
  .statusDetailsText {
    font-size: 13px;
    line-height: 16px;
    margin: 12px 0;
  }

  .cel-pro {
    font-size: 14px;
    line-height: 18px;
  }

  .cel-sec-s {
    font-size: 14px;
    line-height: 18px;
  }

  .submit-bts-o-i-p {
    padding: 12px 24px;
    font-size: 14px;
  }
}
