.viewAllHeading {
  font-family: "GillroyBold";
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  margin-bottom: 8px;
}

.subtitle_head {
  font-family: "GillroyRegular";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
}

.separatorLine {
  background: #414141;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .viewAllHeading {
    font-size: 26px;
    line-height: 26px;
  }
  .subtitle_head {
    font-size: 15px;
    line-height: 15px;
  }
  .separatorLine {
    margin-top: 16px;
    margin-bottom: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .viewAllHeading {
    font-size: 24px;
    line-height: 24px;
  }
  .subtitle_head {
    font-size: 15px;
    line-height: 15px;
  }
  .separatorLine {
    margin-top: 16px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .viewAllHeading {
    font-size: 22px;
    line-height: 22px;
  }
  .subtitle_head {
    font-size: 14px;
    line-height: 14px;
  }
  .separatorLine {
    margin-top: 14px;
    margin-bottom: 18px;
  }
}
@media (max-width: 575px) {
  .viewAllHeading {
    font-size: 18px;
    line-height: 18px;
  }
  .subtitle_head {
    font-size: 13px;
    line-height: 13px;
  }
  .separatorLine {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}
