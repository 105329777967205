.contactUsContainerInner {
  padding: 20px 0 40px 0;
}

.formwrpr {
  background: #303030;
  border-radius: 18px;
  padding: 32px;
}

.c-f-s-btn {
  border: none;
  color: #fff;
  background: #0d61d3;
  border-radius: 40px;
  padding: 20px 80px;
}

.c-f-s-btn:hover {
  opacity: 0.7;
}

.heade-cc-styles {
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  margin-bottom: 8px;
  font-family: "GillroyBold";
  line-height: 24px;
}

.subhead-para-details {
  text-align: center;
  color: #fff;
  margin-bottom: 24px;
  font-family: "GillroyMedium";
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .formwrpr {
    padding: 24px 16px;
  }
  .contactUsContainerInner {
    padding: 0 0 30px 0;
  }
  .heade-cc-styles {
    font-size: 16px;
  }
  .subhead-para-details {
    margin-bottom: 16px;
    font-size: 14px;
  }

  .c-f-s-btn {
    padding: 10px 50px;
  }
}
