.f-p-all-wrpr {
  background: #303030;
  border-radius: 12px;
  padding: 48px 32px;
}

.forgotpasswordContainer {
  padding: 80px 0;
}

.log-in-btsss1 {
  background: #0d61d3;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  color: #ffffff;
  margin-top: 24px;
  font-family: "GillroySemiBold";
  width: 100%;
  border: 0;
  height: 52px;
}
.log-in-btsss2 {
  background: "gray";
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  color: #ffffff;
  margin-top: 24px;
  font-family: "GillroySemiBold";
  width: 100%;
  border: 0;
  height: 52px;
}

.login_head1 {
  font-family: "GillroyBold";
  font-size: 32px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  margin-bottom: 47px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .f-p-all-wrpr {
    padding: 24px 16px;
  }
  .login_head1 {
    font-size: 16px;
    line-height: 18px;
  }
}
