.containerWrprs {
  flex: 1;
  background-color: #1a1a1a;
}

.order_main_wrpr {
  padding: 20px 0;
  min-height: 90vh;
}

/* tab */

.tab-avatar-img {
  /* width: 60px;
  height: 72px;
  display: flex;
  border-radius: 40%;
  overflow: hidden;
  justify-content: center;
  align-content: center; */

  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
}

.tab-all-wrpr {
  background: #303030;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 24px;
  width: 100%;
  border: 0;
}

.tab-all-wrpr:hover {
  opacity: 0.85;
  transform: scale(1.01);
}

.orderDetailsData {
  text-align: left;
  margin: 0px 16px;
}

.orderDetailsName {
  font-size: 18px;
  color: #ffffff;
  font-family: "GillroySemiBold";
  line-height: 24px;
}

.orderDetailsSubTitle {
  color: #a0a3bd;
  font-size: 16px;
  font-family: "GillroyMedium";
  line-break: anywhere;
}

.first-tab-sec {
  display: flex;
  align-items: start;
  flex: 1;
}

.tab-se-sections {
  display: flex;
  flex-direction: row;
}

.daysLeft {
  font-family: "GillroyRegular";
  font-style: normal;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #f0972f;
  padding-right: 24px;
}

.pengingStyles {
  font-family: "GillroySemiBold";
  font-style: normal;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #f0972f;
  border: 1px solid #f2a245;
  border-radius: 40px;
  padding: 8px 16px;
  margin-right: 12px;
}

.tab-se-sections {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-custom-tab-styles .nav-link {
  border-top: none;
  border-left: none;
  border-right: none;
  color: #ffffff;
  border-bottom: "4px solid";
  font-size: 20px;
  font-family: "GillroySemiBold";
}

.my-custom-tab-styles {
  border-bottom-color: #414141;
}

.my-custom-tab-styles .nav-link.active {
  background-color: inherit;
  color: #0d61d3;
  border-bottom-color: #0d61d3;
  border-bottom-width: 4px;
}
.my-custom-tab-styles .nav-link:hover {
  background-color: inherit;
  opacity: 0.85;
  border-bottom-width: 4px;
  border-bottom-color: #414141;
}

/* .my-custom-tab-styles {
  border-bottom-color: #414141;
  font-size: 20px;
} */

.completedStyles {
  font-family: "GillroySemiBold";
  font-style: normal;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #1ec148;
  border: 1px solid #1ec148;
  border-radius: 40px;
  padding: 8px 16px;
}

.expiresStyle {
  font-family: "GillroySemiBold";
  font-style: normal;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #f15252;
  border: 1px solid #f15252;
  border-radius: 40px;
  padding: 8px 16px;
}

.-an-no-o-s {
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.daysLeft-bottom {
  font-family: "GillroyRegular";
  font-style: normal;
  font-size: 13px;
  letter-spacing: -0.01em;
  color: #f0972f;
  display: none;
}
.daysLeft-left {
  font-family: "GillroyRegular";
  font-style: normal;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #f0972f;
  padding-right: 16px;
  display: block;
}

.orderPurposeText {
  color: #a8a8a8;
  font-size: 16px;
  font-family: "GillroyMedium";
  margin: 5px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .tab-all-wrpr {
    padding: 14px;
  }
  .tab-avatar-img {
    width: 48px;
    height: 48px;
  }
  .daysLeft-bottom {
    display: block;
  }
  .daysLeft-left {
    display: none;
  }

  .orderDetailsData {
    margin: 0px 6px;
  }

  .pengingStyles {
    padding: 6px;
    margin-right: 6px;
  }

  .expiresStyle {
    padding: 6px;
  }

  .completedStyles {
    padding: 6px;
  }

  .my-custom-tab-styles .nav-link {
    font-size: 16px;
  }

  .orderDetailsName {
    font-size: 16px;
    line-height: 14px;
  }

  .orderDetailsSubTitle {
    font-size: 13px;
  }

  .orderPurposeText {
    font-size: 13px;
  }

  .order_main_wrpr {
    padding: 0 0 20px 0;
    min-height: 500px;
  }
}
