.homeContainer {
  flex: 1;
  background-color: #1a1a1a;
}

.div-32 {
  height: 32px;
}

.how-it-wrk-wrpr-mian {
  padding: 70px 0;
}

.h-c-l-scroll-wrps {
  max-width: 200px;
  margin-right: 24px;
}

.main-wrpr-h-p-scroll {
  display: flex;
  overflow-x: scroll;
}

.c-list-wrprs-line {
  margin-bottom: 40px;
}

.under-card {
  padding: 80px 0;
}

.cardColor_under-amount {
  background: radial-gradient(
        63.44% 82.03% at 55.58% -15.63%,
        #5adde6 0%,
        rgba(90, 221, 230, 0.26) 53.72%,
        rgba(90, 221, 230, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      26.62deg,
      rgba(249, 124, 89, 0.8) 20.64%,
      rgba(249, 124, 89, 0) 49.82%
    ),
    linear-gradient(56.79deg, #b95be6 33.79%, rgba(185, 91, 230, 0) 72.67%),
    linear-gradient(
      301.08deg,
      rgba(252, 178, 91, 0.91) 20.42%,
      rgba(252, 178, 91, 0) 60.38%
    ),
    linear-gradient(
      141.57deg,
      rgba(78, 173, 235, 0.85) 19.08%,
      rgba(78, 173, 235, 0) 98.72%
    ),
    #d3e4ff;
  background-blend-mode: normal, normal, darken, normal, normal, normal;
  border-radius: 16px;
  height: 150px;
  display: flex;
  align-items: flex-end;
  padding: 14px 16px;
}

.cardColor_under-amount1 {
  background: radial-gradient(
        92.71% 92.71% at 7.59% 128.39%,
        #ffeec2 0%,
        rgba(255, 238, 194, 0.21) 60.42%,
        rgba(194, 233, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        49.19% 88.28% at 27.01% -13.54%,
        rgba(93, 227, 236, 0.73) 0%,
        rgba(93, 227, 236, 0.1679) 59.78%,
        rgba(93, 227, 236, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        114.51% 155.86% at 90.18% 89.06%,
        rgba(254, 75, 139, 0.7) 0%,
        rgba(254, 75, 139, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      237.32deg,
      #454abb 18.14%,
      rgba(69, 74, 187, 0.76) 31.3%,
      rgba(69, 74, 187, 0.39) 49.35%,
      rgba(91, 196, 230, 0) 76.77%
    ),
    radial-gradient(
        118.75% 118.75% at 3.35% 25.78%,
        #308ee6 0%,
        rgba(48, 158, 230, 0) 73.91%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #f2f5fa;
  background-blend-mode: normal, hard-light, normal, darken, normal, normal;
  border-radius: 16px;
  height: 150px;
  display: flex;
  align-items: flex-end;
  padding: 14px 16px;
}

.cardColor_under-amount2 {
  background: linear-gradient(180deg, #341d65 0%, rgba(52, 29, 101, 0) 100%),
    radial-gradient(
        94.51% 124.88% at 94.32% 94.43%,
        rgba(65, 244, 255, 0.78) 0%,
        rgba(128, 217, 255, 0.78) 46.52%,
        rgba(65, 198, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(41deg, #fffce6 4.33%, rgba(255, 252, 230, 0) 73.7%);
  border-radius: 16px;
  height: 150px;
  display: flex;
  align-items: flex-end;
  padding: 14px 16px;
}

.cardColor_under-amount3 {
  background: radial-gradient(
        62.61% 62.61% at 95.23% 6.02%,
        #160e71 0%,
        rgba(19, 13, 92, 0.26) 54.71%,
        rgba(90, 35, 248, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(72.48deg, #ef516d 2.61%, rgba(106, 103, 227, 0) 56.18%),
    radial-gradient(
        45.23% 45.23% at 35.11% -11.02%,
        #7936ae 0%,
        rgba(121, 54, 174, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        94.51% 124.88% at 94.32% 94.43%,
        rgba(65, 244, 255, 0.78) 0%,
        rgba(131, 218, 255, 0.6552) 32.29%,
        rgba(99, 175, 240, 0.3978) 64.06%,
        rgba(43, 90, 211, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(313.04deg, #341d65 0.93%, #604aea 125.68%);
  border-radius: 16px;
  height: 150px;
  display: flex;
  align-items: flex-end;
  padding: 14px 16px;
}

.hm-main-cat-wrpr {
  overflow-x: scroll;
}

.hm-main-cat-wrpr::-webkit-scrollbar {
  display: none;
}

.u-c-i-amounts-rate {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
}

.under-txt {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroyMedium";
  margin-bottom: 4px;
}

.under-amounts-ii {
  font-size: 28px;
  line-height: 45px;
  letter-spacing: 0.02em;
  color: #f8f8f8;
  font-family: "GillroySemiBold";
}

.sep-40-i-m {
  height: 40px;
}

.cat-list0h-top-list {
  display: flex;
  overflow-x: scroll;
  margin-bottom: 30px;
}

.h-p-l-i-heading-name {
  font-size: 20px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #f8f8f8;
  font-family: "GillroySemiBold";
  text-decoration: underline;
}

.h-p-l-i-heading-name:hover {
  opacity: 0.7;
}

.h-p-l-i-link {
  color: #f8f9fa !important;
}

.h-p-l-i-link:hover {
  opacity: 0.7;
}

.h-p-l-i-sct-sec {
  width: "100%";
  aspect-ratio: 1 / 1;
  border-radius: "50%";
  background-image: "linear-gradient(95.32deg, #282828 0.7%, #212021 98.35%)";
}

.s-c-items {
  position: relative;
  min-height: 200px;
  background: linear-gradient(95.32deg, #282828 0.7%, #212021 98.35%);
  /* overflow: hidden; */
  border-radius: 16px;
}

/* .slickBg_banner_wrpr {
  position: absolute;
  right: 0;
  top: 0;
} */

.slick-para-txt {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: #e1e1e1;
  font-family: "GillroyRegular";
}

.headings_banner_slick {
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
  font-family: "GillroyBold";
  margin-bottom: 32px;
}

.ali-i-c-j-home {
  padding: 90px;
}

.sli-con-mains {
  margin-bottom: 32px;
}

.categiries-wors-scroll::-webkit-scrollbar {
  display: none;
}

.cat-list0h-top-list::-webkit-scrollbar {
  display: none;
}

.main-wrpr-h-p-scroll::-webkit-scrollbar {
  display: none;
}

.spinnerWrprNew {
  height: calc(100vh - 96px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h-c-l-scroll-wrps {
    max-width: 190px;
    margin-right: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .headings_banner_slick {
    font-size: 40px;
    line-height: 46px;
  }
  .slick-para-txt {
    font-size: 23px;
    line-height: 34px;
  }
  .ali-i-c-j-home {
    padding: 75px;
  }

  .h-p-l-i-heading-name {
    font-size: 19px;
    line-height: 15px;
  }
  .h-c-l-scroll-wrps {
    max-width: 165px;
    margin-right: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cat-list0h-top-list {
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .headings_banner_slick {
    font-size: 36px;
    line-height: 40px;
  }
  .slick-para-txt {
    font-size: 22px;
    line-height: 33px;
  }

  .ali-i-c-j-home {
    padding: 60px;
  }

  .h-p-l-i-heading-name {
    font-size: 18px;
    line-height: 13px;
  }

  .h-c-l-scroll-wrps {
    max-width: 150px;
    margin-right: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cat-list0h-top-list {
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .headings_banner_slick {
    font-size: 24px;
    line-height: 32px;
  }
  .slick-para-txt {
    font-size: 20px;
    line-height: 30px;
  }

  .ali-i-c-j-home {
    padding: 30px;
  }

  .h-p-l-i-heading-name {
    font-size: 15px;
    line-height: 10px;
  }
  .h-c-l-scroll-wrps {
    max-width: 150px;
    margin-right: 16px;
  }
}

@media (max-width: 575px) {
  .cat-list0h-top-list {
    margin-bottom: 30px;
    margin-top: 24px;
  }
  /* .slickBg_banner_wrpr {
    display: none;
  } */
  .ali-i-c-j-home {
    padding: 25px;
  }
  .headings_banner_slick {
    font-size: 20px;
    line-height: 26px;
  }
  .slick-para-txt {
    font-size: 16px;
    line-height: 26px;
  }
  .s-c-items {
    min-height: 150px;
  }

  .h-p-l-i-heading-name {
    font-size: 16px;
    line-height: 10px;
  }

  .h-c-l-scroll-wrps {
    max-width: 150px;
    margin-right: 16px;
  }
}

@media (max-width: 280px) {
  .h-p-l-i-heading-name {
    font-size: 10px;
    line-height: 10px;
  }
}
