.contactUsContainerInner {
  padding: 20px 0 40px 0;
}

.formwrpr1 {
  background: #303030;
  border-radius: 18px;
  padding: 70px 45px;
}

.c-f-s-btn {
  border: none;
  color: #fff;
  background: #0d61d3;
  border-radius: 40px;
  padding: 20px 80px;
}

.c-f-s-btn:hover {
  opacity: 0.7;
}

.heade-cc-styles {
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  margin-bottom: 8px;
  font-family: "GillroyBold";
  line-height: 24px;
}

.subhead-para-details {
  text-align: center;
  color: #fff;
  margin-bottom: 24px;
  font-family: "GillroyMedium";
  font-size: 16px;
}

.subhead-para-details-bottom {
  text-align: center;
  color: #fff;
  margin-bottom: 16px;
  font-family: "GillroyMedium";
}

.cehckbox-custom1 input {
  height: 18px;
  width: 18px;
  background: #1a1a1a;
  border: 1px solid #4a4a4a;
}

.form-select-custm-class {
  background-color: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  height: 50px;
  font-size: 16px;
}

.form-select-custm-class:hover {
  opacity: 0.85;
}

.phone-number-wrpr {
  display: flex;
  flex-direction: row;
}
.PhoneInputInput {
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  height: 55px;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
}

.PhoneInputCountrySelect {
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  height: 55px !important;
  padding: 0.375rem 0.75rem;
  font-size: 16px !important;
}

.PhoneInputInput:hover {
  opacity: 0.85;
}

.PhoneInput {
  width: 100%;
}
.PhoneInputCountry {
  background: #1a1a1a;
  border: 1px solid #565656;
  border-radius: 8px;
  color: #ffffff;
  height: 55px;
  padding: 0 10px;
}
.PhoneInputCountryError {
  border-color: #ed2e7e !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .formwrpr1 {
    padding: 24px 16px;
  }
}
@media (max-width: 575px) {
  .formwrpr {
    padding: 24px 16px;
  }
  .contactUsContainerInner {
    padding: 0 0 30px 0;
  }
  .heade-cc-styles {
    font-size: 16px;
  }
  .formwrpr1 {
    padding: 24px 16px;
  }
  .form-select-custm-class {
    height: 42px;
    font-size: 14px;
  }
  .subhead-para-details {
    margin-bottom: 16px;
    font-size: 14px;
  }
  .PhoneInputInput {
    height: 42px;
    font-size: 14px;
  }
  .PhoneInputCountrySelect {
    height: 42px !important;
    font-size: 14px !important;
  }
  .PhoneInputCountry {
    height: 42px !important;
    font-size: 14px !important;
  }
  .c-f-s-btn {
    padding: 10px 50px;
  }
}
