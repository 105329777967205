.hiw-container {
  background-color: #1a1a1a;
}

.details-section {
  padding: 80px 0 80px;
}

.ali-i-c-j-home-new {
  padding: 200px 0px 200px 100px;
}

.-an-d-s-head {
  font-family: "GillroyBold";
  color: #ffffff;
  font-size: 48px;
  text-align: center;
  margin-bottom: 80px;
}

.row-wrpr {
  display: flex;
  flex-direction: row;
}

.step-number {
  margin-right: 35px;
}

.details-para {
  font-family: "GillroyRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #f8f8f8;
}

.h2-head {
  font-size: 40px;
  font-family: "GillroyBold";
  color: #ffffff;
}

.-an-step-four-big {
  display: block;
}
.-extra-margin {
  margin-bottom: 24px;
}
.step-number {
  margin-right: 35px;
}

.step-number.-extra-margin.-an-step-four-big {
  width: 100px;
}

.last-step-head {
  font-size: 72px;
  font-family: "GillroyBold";
  font-style: normal;
  background: linear-gradient(
    260.92deg,
    #f0972f 14.82%,
    #ec27ad 50.84%,
    #7134f0 80.54%,
    #13cbf3 106.82%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.d-s-inner-container {
  background: #7134f0;
  border-radius: 32px;
  padding: 40px 70px 0 135px;
  z-index: 1000;
  position: relative;
  margin-top: 100px;
}

.appstoreBtn {
  width: 208px;
  height: 64px;
  left: 170px;
  top: 591px;
  background: linear-gradient(180deg, #a4d7ff 0%, #0d61d3 100%);
  border-radius: 32px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}
.playstoreBtn {
  width: 208px;
  height: 64px;
  left: 402px;
  top: 591px;
  background: linear-gradient(180deg, #ef89a8 0%, #e0376a 100%);
  box-shadow: 0px 4px 12px rgba(224, 55, 106, 0.25);
  border-radius: 32px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.baner-btn-wrpr {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  margin-bottom: 45px;
}
.get-it {
  color: #ffffff;
  font-family: "GillroyRegular";
  font-size: 12px;
  text-align: start;
}
.google-play-text {
  color: #ffffff;
}

p.download-para {
  font-family: "GillroyRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #ffffff;
}

h1.ddparas {
  font-family: "GillroyBold";
  color: #ffffff;
  font-size: 48px;
}

.inner-container {
  width: 100%;
  position: relative;
  padding: 50px;
  border-radius: 24px;
  background-color: #282828;
}

.welcome-txt {
  font-family: "GillroyRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  letter-spacing: 0.03em;
  background: linear-gradient(
    257.71deg,
    #f0972f -5.45%,
    #ec27da 39.29%,
    #7134f0 100%,
    #13cbf3 133.27%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.b-h-stl {
  font-family: "GillroyBold";
  color: #ffffff;
  font-size: 48px;
}

.ba-par-style {
  font-family: "GillroyRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #e1e1e1;
}

.second-step-absolute-wrpr {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.first-relative {
  position: relative;
  z-index: 1;
}
.bottomPhns {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.how-it-works-number-steps {
  width: 100%;
}

.faq-client-wrapper {
  background-color: #2e2e2eba;
  border-radius: 20px;
  padding: 60px 20px;
  margin-top: 100px;
}

.faq-header {
  font-family: "GillroyBold";
  font-size: 48px;
  font-weight: 800;
  line-height: 56px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #ffffff;
}

.faq-desc {
  font-family: "GillroyRegular";
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #ffffff;
}

.accordion,
.accordion-button,
.accordion-item {
  background-color: #2e2e2eba;
}

.accordion-button {
  color: #ffffff !important;
  font-family: "GillroySemiBold";
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.accordion {
  --bs-accordion-border-color: #3a3a3a;
  --bs-accordion-color: #ffffff;
}

.accordion-button:not(.collapsed) {
  background-color: #2e2e2eba;
}

.accordion-item {
  color: #ffffff;
  font-family: "GillroyRegular";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.accordion-button::after {
  background-image: url("/src/Assets/faq.png");
}

.accordion-body {
  width: 75%;
}

@media only screen and (min-width: 1200px) and (max-width: 1449px) {
  .ali-i-c-j-home-new {
    padding: 100px 0px 90px 90px;
  }

  .faq-client-wrapper {
    padding: 50px 20px;
  }

  .faq-header {
    font-size: 44px;
    line-height: 56px;
  }

  .faq-desc {
    font-size: 20px;
    line-height: 32px;
  }

  .accordion-item {
    font-size: 16px;
    line-height: 26px;
  }

  .accordion-button {
    font-size: 20px;
    line-height: 26px;
  }

  .last-step-head {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ali-i-c-j-home-new {
    padding: 50px 0px 50px 60px;
  }

  .faq-client-wrapper {
    padding: 40px 20px;
  }

  .faq-header {
    font-size: 34px;
    line-height: 56px;
  }

  .faq-desc {
    font-size: 19px;
    line-height: 32px;
  }

  .accordion-item {
    font-size: 16px;
    line-height: 26px;
  }

  .accordion-button {
    font-size: 18px;
    line-height: 26px;
  }

  .last-step-head {
    font-size: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .last-step-head {
    font-size: 40px;
  }

  .h2-head {
    font-size: 35px;
  }

  .how-it-works-number-steps {
    width: 100%;
  }
  .step-number.-extra-margin.-an-step-four-big.new-class-imgs0an img {
    width: 100%;
  }
  .ba-par-style {
    font-size: 16px;
    line-height: 30px;
  }
  .b-h-stl {
    font-size: 37px;
  }
  .ali-i-c-j-home-new {
    padding: 35px 0px 0px 45px;
  }
  .scroller-img {
    width: 50px;
    height: auto;
  }

  .faq-client-wrapper {
    padding: 30px 20px;
  }

  .faq-header {
    font-size: 28px;
    line-height: 56px;
  }

  .faq-desc {
    font-size: 18px;
    line-height: 32px;
  }

  .accordion-item {
    font-size: 16px;
    line-height: 26px;
  }

  .accordion-button {
    font-size: 18px;
    line-height: 26px;
  }

  .accordion-body {
    width: 85%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-sections.inner-container {
    padding: 30px;
  }
  .res-m-b-s {
    margin-bottom: 0;
    margin-top: 24px;
  }
  .details-section {
    padding: 40px 0 40px;
  }
  .-an-d-s-head {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .last-step-head {
    font-size: 40px;
  }

  .h2-head {
    font-size: 33.5px;
  }

  h1.ddparas {
    font-size: 32px;
    text-align: center;
  }
  .how-it-works-number-steps {
    width: 100%;
  }
  .step-number.-extra-margin.-an-step-four-big.new-class-imgs0an img {
    width: 40%;
  }
  .ba-par-style {
    font-size: 16px;
    line-height: 22px;
  }
  .b-h-stl {
    font-size: 26px;
  }
  .ali-i-c-j-home-new {
    padding: 35px 0px 0px 30px;
  }
  .scroller-img {
    width: 40px;
    height: auto;
  }

  .faq-client-wrapper {
    padding: 30px 20px;
  }

  .faq-header {
    font-size: 26px;
    line-height: 56px;
  }

  .faq-desc {
    font-size: 17px;
    line-height: 32px;
  }

  .accordion-item {
    font-size: 15px;
    line-height: 26px;
  }

  .accordion-button {
    font-size: 17px;
    line-height: 26px;
  }

  .accordion-body {
    width: 90%;
  }
}

@media (max-width: 575px) {
  .banner-sections.inner-container {
    padding: 24px;
  }
  .b-h-stl {
    font-size: 32px;
  }
  .res-m-b-s {
    margin-bottom: 0;
    margin-top: 24px;
  }
  .details-section {
    padding: 40px 0 40px;
  }
  .-an-d-s-head {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .row-wrpr {
    flex-direction: column;
  }
  .step-number img {
    width: 30%;
  }
  .step-number.-extra-margin.-an-step-four-big.new-class-imgs0an img {
    width: 30%;
  }
  .step-number {
    margin-right: 0;
    text-align: center;
    margin-bottom: 16px;
  }
  .h2-head {
    font-size: 32px;
    font-family: "GillroyBold";
    color: #ffffff;
  }
  .last-step-head {
    font-size: 40px;
  }
  .step-number.-extra-margin.-an-step-four-big {
    width: 100%;
  }
  .d-s-inner-container {
    padding: 24px 24px 0 24px;
  }
  h1.ddparas {
    font-size: 32px;
    text-align: center;
  }
  .appstoreBtn {
    margin-right: 12px;
  }
  .how-it-works-number-steps {
    width: 30%;
  }
  .ali-i-c-j-home-new {
    padding: 15px;
  }
  .scroller-img {
    width: 60px;
    height: auto;
  }

  .faq-client-wrapper {
    padding: 25px 10px;
    margin-top: 60px;
  }

  .faq-header {
    font-size: 20px;
    line-height: 48px;
  }

  .faq-desc {
    font-size: 13px;
    line-height: 32px;
  }

  .accordion-item {
    font-size: 13px;
    line-height: 26px;
  }

  .accordion-button {
    font-size: 14px;
    line-height: 26px;
  }

  .accordion-body {
    width: 95%;
  }
}
